<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement innerPage-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12"> 
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap">
                            <div class="bdPage">
                                <h2>Technical Analysis</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/dashboard">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">Technical Analysis</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch">
                                <form class="searchBar">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="Search.." name="" /> 
                                        <a class="searchButton" href="#"><vue-feather type="search"></vue-feather> </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement mainNewsBlock">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="card-body topNews p-0">
                            <img src="assets/images/news/newsblock-7.png" alt="News" title="News Image" class="img-fluid">
                            <div class="content">
                                <h3><a href="javascript:void(0)"> Dollar Down as Fading Omicron Fears Boost Investor Risk Appetite</a></h3>
                                <p>The USD/JPY pair inched down 0.03% to 114.35, with Japan’s cabinet approving a record initial budget for the year starting in April 2022. According to the country’s Finance Ministry, Japan plans JPY107.6 trillion yen ($941.26 billion) in overall spending for the year ending March 2023, a 0.9% increase from the current year’s initial budget.</p>
                                <div class="d-flex align-items-center justify-content-between">
                                    <ul class="datTime d-flex align-items-center">
                                        <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                        <li> 1 hour ago</li>
                                    </ul>
                                    <ul class="bookmark d-flex align-items-center">
                                        <li class="me-3 active"><a href="javascript:void(0)"><img src="assets/images/bookmark.png" alt="Icon" title="Bookmark"> </a></li>
                                        <li><a href="javascript:void(0)"><vue-feather type="share-2"></vue-feather></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="card-body d-flex align-items-start border-bottom mb-3 p-0 parentNews">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img src="assets/images/news/newsblock-8.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">EUR/USD climbs to one-week high, around mid-1.1300s  post-ECB decision</a></h6>
                                <p class="mb-2">US stock futures rose markedly on Monday, lifted by reports that both personal income and spending..</p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                    <li> 1 hour ago</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-start border-bottom mb-3 p-0 parentNews">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img src="assets/images/news/newsblock-9.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">AUD/USD consolidates in a range near weekly top, just below 0.7180 resistance zone</a></h6>
                                <p class="mb-2">US stock futures rose markedly on Monday, lifted by reports that both personal income and spending..</p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                    <li> 1 hour ago</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-start border-bottom mb-3 p-0 parentNews">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img src="assets/images/news/newsblock-10.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">Gold Price Forecast: XAU/EUR seesaws below €1,600 as EUR traders await ECB</a></h6>
                                <p class="mb-2">US stock futures rose markedly on Monday, lifted by reports that both personal income and spending..</p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                    <li> 1 hour ago</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-start p-0 parentNews">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img src="assets/images/news/newsblock-11.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">China Pushes Back on Strong Yuan With Record Run of Weak Fixings</a></h6>
                                <p class="mb-2">(Bloomberg) -- China set a weaker-than-forecast yuan fixing for a record 15th day on Friday as..</p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                    <li> 1 hour ago</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement slideNews space">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-2 col-md-4">
                        <div class="position-relative hoverNews">
                            <img src="assets/images/news/newsblock-2.png">
                            <a href="javascript:void(0)" class="position-absolute mb-0">Dollar Static Ahead of Key CPI Release Flat After GDP Data</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 col-md-4">
                        <div class="position-relative hoverNews">
                            <img src="assets/images/news/newsblock-3.png">
                            <a href="javascript:void(0)" class="position-absolute mb-0">USD/JPY steadily climbs to 113.75-80 area, fresh daily..</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 col-md-4">
                        <div class="position-relative hoverNews">
                            <img src="assets/images/news/newsblock-4.png">
                            <a href="javascript:void(0)" class="position-absolute mb-0">New Zealand dollar extends losses</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 col-md-4">
                        <div class="position-relative hoverNews">
                            <img src="assets/images/news/newsblock-1.png">
                            <a href="javascript:void(0)" class="position-absolute mb-0">Gold: One market to watch over the CPI print</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 col-md-4">
                        <div class="position-relative hoverNews">
                            <img src="assets/images/news/newsblock-5.png">
                            <a href="javascript:void(0)" class="position-absolute mb-0">Gold Up, Dollar Weakens Over Holiday Risk-On Rally</a>
                        </div>
                    </div>
                    <div class="col-12 col-xl-2 col-md-4">
                        <div class="position-relative hoverNews">
                            <img src="assets/images/news/newsblock-6.png">
                            <a href="javascript:void(0)" class="position-absolute mb-0">Dalal St Likely to Open in Green; Factors Affecting Nifty50 on Dec 24</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement newsMarket space-footer">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="marketTitle">
                            <h3 class="mb-4">Market</h3>
                            <ul class="toplinks pb-2 d-flex align-items-center border-bottom">
                                <li class="ps-0"><a href="javascript:void(0)" class="active">All</a></li>
                                <li><a href="javascript:void(0)">Forex</a> </li>
                                <li><a href="javascript:void(0)">Crypto</a> </li>
                                <li><a href="javascript:void(0)">Currencies</a> </li>
                              <li><a href="javascript:void(0)">Indices</a></li>
                            </ul>
                        </div>
                        <div class="card-body d-flex align-items-start border-bottom mb-2 px-0">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img src="assets/images/news/newsblock-12.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">Japan’s Prices Rise at Faster Pace as Energy Costs Swell</a></h6>
                                <p>Rises in global commodity prices and higher import costs on a weaker yen have pushed Japan’s inflation back above zero. </p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                    <li> 1 hour ago</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-start border-bottom mb-2 px-0">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img src="assets/images/news/newsblock-13.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">U.K. shares higher at close of trade; Investing.com United Kingdom 100 up 2.59%</a></h6>
                                <p>Biggest losers included British American Tobacco PLC (LON:BATS), which lost 1.95% or 54.5 points to trade at 2745.5 in late trade. </p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                    <li> 1 hour ago</li>
                                </ul>
                            </div>
                        </div>
                        <div class="card-body d-flex align-items-start px-0">
                            <span class="flex-shrink-0 mb-2 mb-md-0"><img src="assets/images/news/newsblock-14.png" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3">
                                <h6><a href="javascript:void(0)">Oil Climbs to Fresh Four-Week High as Omicron Concerns Ease</a></h6>
                                <p>Today we had some bullish factors to drive up market sentiment,” said Andrew Lebow, senior partner at Commodity Research Group.</p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="portfolios.com">Portfolios.com </a></li>
                                    <li> 1 hour ago</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="marketTitle">
                            <h3 class="mb-4 pb-md-4">Most Read</h3>
                        </div>
                        <ul class="mostRead">
                            <li><a href="javascript:void(0)">Opinion: Chinese stocks cut $600 billion from U.S. markets in 2021, and are just getting started</a></li>
                            <li><a href="javascript:void(0)">Is the stock market open on Christmas Eve? Here are the upcoming holiday trading hours.</a></li>
                            <li><a href="javascript:void(0)">Google, Intel, GM join list of big companies skipping CES in person due to COVID fears</a></li>
                            <li><a href="javascript:void(0)">United, Delta cancel dozens of holiday-weekend flights as omicron spreads</a></li>
                            <li><a href="javascript:void(0)">DoorDash will require all workers to deliver goods or perform other gigs, and some of them aren’t happy</a></li>
                            <li><a href="javascript:void(0)">Dow closes over 260 points higher Wednesday and stocks book 2nd straight gain as omicron fears subside</a></li>
                            <li><a href="javascript:void(0)">I’ll have $5 million for retirement when I sell my dental practice next year — but my wife and kids don’t want me to retire</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
export default {
    data() {
        return {

        }
    },
    components: {
        bannerSlider:bannerslider,

    },     
}
</script>
